<template>

  <!--  Content Parameters-->
  <div v-if="!isStyle && !isFormat">
    <!--  Accordion topics-->
    <div class="mt-n1" v-if="selectedTopicIndex === null">

      <draggable
          v-model="element.config.elements"
          :forceFallback="true"
          :empty-insert-threshold="120"
          v-bind="opt"
          @start="drag = true"
          @change="updateSettings"
          handle=".topic-handle"
          @end="drag = false">

        <transition-group type="transition" :name="!drag ? 'flip-list' : null">
          <v-hover
              #default="{hover}"
              v-for="(item,index) in element.config.elements" :key="index"
          >
            <v-sheet
                @click="selectedTopicIndex = index"
                :style="`box-shadow : 0 0 0 ${hover ? 2 : 1}px ${hover ? wsACCENT : wsBACKGROUND}`"
                class="pointer d-flex justify-space-between align-center wsRoundedLight  mt-2 pr-3"
                height="48"
            >
              <div class="d-flex align-center ">
                <v-sheet min-width="24" style="cursor: grab;"  class="topic-handle"  color="transparent">
                  <v-icon v-if="hover"  :color="wsACCENT">mdi-drag-vertical</v-icon>
                </v-sheet>

                <h5 class="cut-text" style="font-size: 12px" >
                  {{ item.title || $t('AddItemTitle') }}
                </h5>
              </div>
              <div v-if="hover" class="d-flex align-center">
                <v-btn
                    @click.stop="duplicateItem(index)"
                    small icon :color="wsDARKLIGHT">
                  <v-icon size="18">mdi-content-copy</v-icon>
                </v-btn>
                <v-btn
                    @click.stop="deleteTopic(index)"
                    small icon :color="wsDARKLIGHT">
                  <v-icon>mdi-delete-outline</v-icon>
                </v-btn>
              </div>

            </v-sheet>
          </v-hover>
        </transition-group>
      </draggable>

        <portal to="element_settings_footer">
          <ws-button
              @click="addTopic"
              label="AddTopic"
              class="mb-2"
              block
          />
        </portal>

    </div>
    <!-- Topic edit -->
    <div v-else>

      <!-- Return button -->
      <div
          class="d-flex justify-space-between align-center mb-5">
        <h5 @click="selectedTopicIndex = null"
            :style="`color : ${wsACCENT}`"
            class="pointer text-no-wrap"
        >
          <v-icon class="mr-2" :color="wsACCENT">mdi-arrow-left</v-icon>
          {{ $t('GoBack') }}
        </h5>
        <h5 class="text-right">{{ $t('avalon.accordion.topic') }}</h5>
      </div>

      <ws-text-field
          @change="editTopic('title' , $event)"
          :value="element.config.elements[selectedTopicIndex].title"
          :label="$t('Name')"
          :placeholder="$t('EnterTopicName')"
      />

      <ws-avalon-text-input
          class="mt-5"
          @input="editTopic('text' , $event)"
          :label="$t('SideBarText')"
          v-model="element.config.elements[selectedTopicIndex].text" />


      <div class="d-flex justify-center mt-5">

        <ws-tooltip :text="$t('DeleteTopic')">
          <v-btn @click="deleteTopic(selectedTopicIndex , true)" icon :color="wsACCENT">
            <v-icon>mdi-delete-outline</v-icon>
          </v-btn>
        </ws-tooltip>

      </div>

    </div>

  </div>

  <!--  Format Parameters-->
  <div v-else-if="isFormat">
    <div class="fill-height">
      <!-- Heading Font Style -->
      <a-parameter-select
          @input="updateSettings"
          v-model="element.config.heading_font_style"
          :items="fontStyles"
          label="Heading"
          :label-color="wsACCENT"
          :value-color="wsACCENT"
          null-text="Default"
      />
      <v-divider class="mt-3 mb-6" style="border-width : 1px" :style="`border-color : ${wsBACKGROUND}`" />
      <!-- Text Font Style -->
      <a-parameter-select
          @input="updateSettings"
          v-model="element.config.text_font_style"
          :items="fontStyles"
          label="Text"
          :label-color="wsACCENT"
          :value-color="wsACCENT"
          null-text="Default"
      />
      <v-divider class="mt-3 mb-6" style="border-width : 1px" :style="`border-color : ${wsBACKGROUND}`" />

      <!-- Expand First-->
      <a-parameter-switch
          class="mt-10"
          @input="updateSettings"
          v-model="element.config.expand_first"
          label="avalon.accordion.expand_first"

      />
      <v-divider class="mt-3 mb-6" style="border-width : 1px" :style="`border-color : ${wsBACKGROUND}`" />
      <!-- Multiple expand -->
      <a-parameter-switch
          @input="updateSettings"
          v-model="element.config.multiple_expand"
          label="avalon.accordion.multiple_expand"
      />
      <v-divider class="mt-3 mb-6" style="border-width : 1px" :style="`border-color : ${wsBACKGROUND}`" />
      <!-- Display separator -->
      <a-parameter-switch
          @input="updateSettings"
          v-model="element.config.display_line"
          label="avalon.accordion.display_line"
      />
      <v-divider class="mt-3 mb-6" style="border-width : 1px" :style="`border-color : ${wsBACKGROUND}`" />
      <!-- Size -->
      <a-parameter-select
          @input="updateSettings"
          v-model="element.config.size"
          :items="accordionSizes"
          label="avalon.accordion.topics_size"
          :label-color="wsDARKER"
          :value-color="wsACCENT"
          null-text="Default"
      />
      <v-divider class="mt-3 mb-6" style="border-width : 1px" :style="`border-color : ${wsBACKGROUND}`" />
    </div>
  </div>
  <!--  Style Parameters-->
  <div v-else>

    <ws-accordion
        :items="styleNavigationSelect"
    >
      <template #item.color>
        <element-style-settings
            @input="updateSettings"
            v-model="element.config"
            :element="element"
            :block-style="blockStyle"
        />
      </template>
      <template #item.font>
        <element-style-settings
            @input="updateSettings"
            v-model="element.config"
            :element="element"
            fonts
        />
      </template>
    </ws-accordion>


  </div>
</template>

<script>
import {mapState} from "vuex";
import draggable from "vuedraggable";
// import fontStyleParamPicker from "@/components/AvalonEditor/UI/fontStyleParamPicker";
import wsAvalonTextInput from "@/components/AvalonEditor/UI/wsAvalonTextInput/wsAvalonTextInput";
import elementStyleSettings from "@/components/AvalonEditor/Dialogs/elements/elementStyleSettings";
export default {
  name: "avalonTextSettings",
  components : {
    elementStyleSettings,
    draggable,
    wsAvalonTextInput
  },
  props : {
    value : {
      type : Object,
      default() { return {} }
    },
    isStyle : {
      type : Boolean,
      default : false
    },
    isFormat: {
      type : Boolean,
      default : false
    },
    element : {
      type : Object,
      default() { return {} }
    },
    blockStyle : {
      type : String,
      default : 'light_1'
    }
  },
  data() {
    return {
      config : {},
      selectedTopicIndex : null,
      opt : {
        animation: 200,
        ghostClass: "ghost"
      },
      drag : false,
    }
  },
  computed : {
    ...mapState('avalon',['course' , 'pagesSelect' , 'coursesSelect']),

    styleNavigationSelect() {
      return [
        { name : this.$t('Color') , value : 'color' },
        { name : this.$t('Fonts') , value : 'font' }
      ]
    },

    fontStyles() {
      return [
        { text : this.$t('avalon.color_styles.h1') , value : 'h1' },
        { text : this.$t('avalon.color_styles.h2') , value : 'h2' },
        { text : this.$t('avalon.color_styles.h3') , value : 'h3' },
        { text : this.$t('avalon.color_styles.h4') , value : 'h4' },
        { text : this.$t('avalon.color_styles.p')  , value : 'p'},
        { text : this.$t('avalon.color_styles.h5') , value : 'h5' },
        { text : this.$t('avalon.color_styles.h6') , value : 'h6' },
      ]
    },

    accordionSizes() {
      return [
        { text : this.$t('Small') , value : 'small' },
        { text : this.$t('Medium') , value : 'medium' },
        { text : this.$t('Large') , value : 'large' },
      ]
    },

  },
  methods : {

    updateSettings() {
      this.element = this.COPY(this.element)
      this.$emit('input' , this.COPY(this.element.config))
    },

    editTopic(key , value) {
      this.element.config.elements[this.selectedTopicIndex][key] = value
      this.element = this.COPY(this.element)
      this.$emit('input' , this.COPY(this.element.config) )
    },
    addTopic() {
      this.element.config.elements.push({
        title : this.$t('avalon.accordion.topic'),
        text  : "<p>" + this.$t('avalon.accordion.text_placeholder') + "</p>",
        expanded : false
      })
      this.element = this.COPY(this.element)
      this.$emit('input' , this.COPY(this.element.config) )
    },
    deleteTopic(index , fromEdit = false) {
      if ( fromEdit ) {
        this.selectedTopicIndex = null
      }
      this.element.config.elements.splice(index , 1)
      this.element = this.COPY(this.element)
      this.$emit('input' , this.COPY(this.element.config))
    },
    duplicateItem(index) {
      let item =  this.COPY(this.element.config.elements[index])
      item.expanded = false;
      this.element.config.elements.splice(index + 1 , 0 , item)
      this.updateSettings()
    },
    changeButtonStyle(value) {
      this.element.config.style = value
      this.element = this.COPY(this.element)
      this.$emit('input' , this.COPY(this.element.config))
    },
  },
  mounted() {
    this.config = this.COPY(this.value)
  }

}
</script>

<style scoped>
.flip-list-move {
  transition: transform 0.5s;
}
.no-move {
  transition: transform 0s;
}
.ghost {
  opacity: 0.5;
  background: #c8ebfb;
}
.list-group {
  min-height: 20px;
}
.list-group-item {
  cursor: move;
}
.list-group-item i {
  cursor: pointer;
}
.cut-text {
  display: -webkit-box;
  -webkit-line-clamp: 1;
  -webkit-box-orient: vertical;
  overflow: hidden;
  text-overflow: ellipsis;
}
</style>